<template>
    <div class="parallelograms-wrapper">
        <div class="parallelogram parallelogram-1" />
        <div class="parallelogram parallelogram-2" />
        <div class="parallelogram parallelogram-3" />
        <div class="parallelogram parallelogram-4" />
        <div class="parallelogram parallelogram-5" />
        <div class="parallelogram parallelogram-6" />
        <div class="parallelogram parallelogram-7" />
    </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class Parallelograms extends Vue {}
</script>

<style lang="scss" scoped>
$p-dark: #023aae;

.parallelograms-wrapper {
	.parallelogram {
		position: absolute;
		border-radius: 4px;
		box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
		transform: skewY(20deg);
	}

	.parallelogram-1 {
		background: $lcpGrapefruit;
		height: 200px;
		width: 115px;
		left: 7%;
		top: 40px;
	}

	.parallelogram-2 {
		background: lighten($lcpGrapefruit, 20);
		height: 100px;
		width: 60px;
		left: 25%;
		top: 50px;
	}

	.parallelogram-3 {
		background: $p-dark;
		height: 100px;
		width: 60px;
		left: 80%;
		top: -30px;
	}

	.parallelogram-4 {
		background: $p-dark;
		height: 180px;
		width: 170px;
		left: 54%;
		bottom: -30px;
	}
	.parallelogram-5 {
		background: $p-dark;
		height: 120px;
		width: 85px;
		left: 78%;
		bottom: 60px;
	}

	.parallelogram-6 {
		background: $p-dark;
		height: 100px;
		width: 80px;
		left: 65%;
		bottom: 145px;
	}

	.parallelogram-7 {
		background: lighten($p-dark, 50);
		height: 100px;
		width: 60px;
		left: 20%;
		bottom: 80px;
	}
}
</style>
