<template>
    <div class="loading-overlay flex flex--row-center">
        <div class="loading">
            <span class="spinner-icon">
                <font-awesome-icon
                    :icon="['fad', 'spinner-third']"
                    spin
                />
            </span>
            Loading...
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
@Component
export default class LoadingOverlay extends Vue {
}
</script>

<style lang="scss" scoped>
.loading-overlay {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: rgba(245, 245, 245, 0.986);
	z-index: 4;
	.loading {
		display: flex;
		flex-direction: column;
		align-items: center;
		.spinner-icon {
			display: block;
			font-size: 5rem;
		}
	}
}
</style>
