<template>
    <transition name="fade">
        <div
            class="modal"
            :class="{ 'is-active': showError }"
        >
            <div class="modal-background" />
            <div class="modal-content">
                <div class="box">
                    <div class="top">
                        <font-awesome-icon
                            :icon="['fa', 'exclamation-triangle']"
                            class="error-icon"
                        />
                        <h1 class="title">
                            {{ errorText }}
                        </h1>
                    </div>
                    <div class="bottom">
                        <p
                            v-for="error in networkErrorMessages"
                            :key="error"
                        >
                            {{ error }}
                        </p>
                        <pre v-if="isNetworkValidationError">{{ networkValidationErrorMessage }}</pre>
                        <button
                            class="btn btn--default"
                            @click="close"
                        >
                            Close
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script lang="ts">
import { Vue,  Component } from 'vue-property-decorator';

@Component
export default class NetworkError extends Vue {

    get networkValidationErrorMessage (): string {
        return this.$store.getters.getNetworkValidationMessage;
    }

    get isNetworkGenericError (): boolean {
        return this.networkErrorMessages.length ? true : false;
    }

    get isNetworkValidationError (): boolean {
        return this.networkValidationErrorMessage.length ? true : false;
    }

    get errorText (): string {
        return this.isNetworkGenericError ? 'Network Error' : 'Validation Error';
    }

    get showError (): boolean {
        return this.isNetworkGenericError || this.isNetworkValidationError;
    }

    get networkErrorMessages (): string[] {
        return this.$store.getters.getNetworkErrorMessages;
    }

    close (): void {
        this.$store.dispatch('clearAPIMessages' );
    }

}
</script>

<style lang="scss" scoped>
pre {
    white-space: pre-wrap;
    padding: 1rem;
    text-align: left;
}
.modal {
	display: none;
	position: fixed;
	z-index: 9999;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	overflow: auto;
	background-color: rgba(0, 0, 0, 0.5);
}

.modal.is-active {
	display: flex;
	align-items: center;
	justify-content: center;
}

.modal-background {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
	z-index: 1;
	background-color: #fff;
	border-radius: 0.25rem;
	box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
	width: 25vw;
}

.box {
	margin: 0 auto;
	max-width: 500px;
	text-align: center;
}
.title {
	font-size: 1.5rem;
	margin-top: 2rem;
}
.top,
.bottom {
	padding: 1.5rem;
	display: flex;
	flex-direction: column;
	align-items: center;
}
.error-icon {
	font-size: 8rem;
}
.top {
	background-color: $ragRed;
	color: white;
}
.bottom {
	background-color: $bgColor;
}

p {
	margin-bottom: 1rem;
}
</style>
