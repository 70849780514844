<template>
    <div>
        <p>
            {{ healthCheckText }}
            <font-awesome-icon
                class="fa-fw"
                :icon="['fal', icon]"
                :spin="iconSpinning"
                :class="classObject"
            />
        </p>
        <p class="description">
            {{ description }}
        </p>
    </div>
</template>

<script lang="ts">

import { Component, Vue,Prop } from 'vue-property-decorator';
import {TApiLoadingStatus} from '@/models/TApiLoadingStatus';

@Component
export default class HealthCheckApiCheckIcon extends Vue {
    @Prop({type: Number, required: true}) loadingStatus!: TApiLoadingStatus;

    @Prop({type: Boolean, required: true}) isSuccessful!: boolean;

    @Prop({type: String, required: true}) name!: string;

    @Prop({type: String, required: true}) description!: string;

    get classObject (): {
        success: boolean;
        error: boolean;
        } {
        return {
            success: this.loadingStatus === 2 && this.isSuccessful,
            error: this.loadingStatus === 2 && !this.isSuccessful
        };
    }

    get icon (): string {
        return this.loadingStatus === 0 ? 'pause-circle'
            : this.loadingStatus === 1 ? 'spinner'
                : this.isSuccessful ? 'check-circle'
                    : 'exclamation-triangle';
    }

    get iconSpinning (): boolean {
        return this.loadingStatus === 1;
    }

    get healthCheckText (): string {
        return this.loadingStatus === 0 ? `Waiting to call ${this.name} API`:
            this.loadingStatus === 1 ? `Checking ${this.name} API`
                : this.isSuccessful ? `${this.name} API run successfully`
                    : `Error with ${this.name} API`;
    }

}

</script>
<style lang="scss" scoped>
p {
    font-size: 1.2rem; // match the font size of the table cells
    line-height: 1.8rem;
}
.success {
    color: $ragGreen;
}
.error {
    color: $ragRed;
}
.description {
   margin-left: 1rem;
   padding-left: .5rem;
   border-left: 3px solid $lcpWatermelon;
}

</style>