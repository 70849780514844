import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import '@/api/intercept';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
    faPlus,
    faTrashAlt,
    faEdit,
    faSave,
    faUndo,
    faSignOut,
    faCogs,
    faAnalytics,
    faTimes,
    faUsers,
    faCalendar,
    faPencil,
    faCopy,
    faTrash,
    faSearch,
    faSpinner,
    faInfoSquare,
    faInfoCircle as falInfoCircle,
    faHistory,
    faLink as falLink,
    faCalendarPlus,
    faCalendarEdit,
    faExternalLinkAlt,
    faCrosshairs,
    faCheck as falCheck,
    faCheckCircle,
    faExclamationTriangle as falExclamationTriangle,
    faPauseCircle,
    faHandPointer,
    faCheckDouble,
    faBadgeCheck,
    faCaretUp,
    faCaretDown,
    faNetworkWired,
} from '@fortawesome/pro-light-svg-icons';
import {
    faSpinnerThird,
    faUsersClass,
    faUserFriends,
    faUser,
    faInfoCircle,
    faTimesCircle,
    faExclamationTriangle,
    faCheck,
    faFileSignature,
    faFingerprint,
    faBolt,
    faTable,
    faUserChart,
    faBalanceScaleLeft,
    faBriefcase,
    faSitemap,
    faQuestionSquare,
    faWaveform
} from '@fortawesome/pro-duotone-svg-icons';
import {
    faChevronDown,
    faExclamationCircle,
    faFileCheck,
    faFileExclamation,
    faHeartbeat,
    faWrench,

} from '@fortawesome/pro-regular-svg-icons';
import {
    faCheck as fasCheck,
    faExclamationTriangle as fasExclamationTriangle,
    faTimesCircle as fasTimesCircle,
    faFileUpload,
    faFileDownload,
    faFileExcel,
    faUserCog,
    faLink,
} from '@fortawesome/pro-solid-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

library.add(
    faCheckDouble,
    faHandPointer,
    faBadgeCheck,
    faWaveform,
    faQuestionSquare,
    faSitemap,
    faBriefcase,
    faBalanceScaleLeft,
    faUserChart,
    faAnalytics,
    faBolt,
    faCalendar,
    faCheck,
    faChevronDown,
    faCogs,
    faCopy,
    faEdit,
    faExclamationCircle,
    faExclamationTriangle,
    faFileCheck,
    faFileDownload,
    faFileExcel,
    faFileExclamation,
    faFileSignature,
    faFileUpload,
    faFingerprint,
    faHeartbeat,
    faHistory,
    faInfoCircle,
    faInfoSquare,
    faLink,
    faPencil,
    faPlus,
    faSave,
    faSearch,
    faSignOut,
    faSpinner,
    faSpinnerThird,
    faTimes,
    faTimesCircle,
    faTrash,
    faTrashAlt,
    faUndo,
    faUser,
    faUserCog,
    faUserFriends,
    faUsers,
    faUsersClass,
    falLink,
    fasCheck,
    fasExclamationTriangle,
    fasTimesCircle,
    faCalendarPlus,
    faCalendarEdit,
    faExternalLinkAlt,
    faCrosshairs,
    falCheck,
    faTable,
    faCheckCircle,
    faPauseCircle,
    falExclamationTriangle,
    falInfoCircle,
    faWrench,
    faCaretUp,
    faCaretDown,
    faNetworkWired,
);

Vue.component('FontAwesomeIcon', FontAwesomeIcon);

Vue.config.productionTip = false;

new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount('#app');
