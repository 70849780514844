<template>
    <div class="login">
        <div class="left">
            <div class="logo">
                <h1>LCP Monitor Pro</h1>
            </div>
            <div class="primary-button-lg login-action">
                <div
                    class="btn-content"
                    @click="login"
                >
                    Log in
                    <font-awesome-icon
                        :icon="['fad', 'user-friends']"
                        class="icon"
                    />
                </div>
            </div>
        </div>
        <div class="right">
            <Parallelograms />
            <div class="innerText">
                <div class="heading">
                    One tool,
                </div>
                <div class="heading">
                    All of your investment monitoring needs
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { isValidRedirectUrl } from '@/utils/isValidRedirectUrl';

import Parallelograms from '@/components/Parallelograms.vue';

@Component({
    components: {
        Parallelograms,
    },
})
export default class Login extends Vue {

    public async login (): Promise<void> {
        try {
            await this.$store.dispatch('auth/login');
        } catch (error) {
            this.$store.dispatch('pushNetworkErrorMessage', 'Login failed. Please try again.');
        }
        const url = this.$route.query.url as string;
        if (url && isValidRedirectUrl(url)) {
            window.location.href = url;
        } else {
            window.location.href = '';
        }

        // This code block might be redundant. We are not sure if it is used.
        this.$nextTick(() => {
            this.$store.dispatch('auth/getToken');
        });
    }
}
</script>
<style lang="scss" scoped>
@import '@/assets/scss/abstracts/_mixin.scss';
.login {
    display: flex;
    min-height: 100svh;
    overflow: hidden;

    .left, .right {
        width: 50%;
        height: 100svh;
    }

    .right {
        background-position: right bottom;
        background-repeat: no-repeat;
        background: $lcpDarkBlue;
        color: $text-white;
        position: relative;

        @include responsive('md') {
            display: none;
        }

        .innerText {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            max-width: 350px;

            p {
                margin-top: 1rem;
            }

            .heading {
                font-size: 3rem;
                font-weight: 300;
            }
        }
    }

    .left {
        background-color: $bg-white;
        padding: 5rem;
        position: relative;

        .login-action {
            margin-top: 4rem;
        }

        @include responsive('md') {
            height: 100svh;
            padding: 2rem !important;
            width: 100% !important;
        }

        .logo {
            margin-bottom: 1rem;

            img {
                height: 5rem;
            }
        }
    }
}
</style>
