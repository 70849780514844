<template>
    <div class="top-bar">
        <ManagerSettingsBreadcrumb :breadcrumb="$store.state.breadcrumb" />
        <HealthCheckApiCheckIconContainer
            v-if="isHealthCheckRoute"
            :selected-scheme-currency="selectedSchemeCurrency"
        />
    </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';
import HealthCheckApiCheckIconContainer from '@/components/MonitorManager/HealthCheckApiCheckIconContainer.vue';
import ManagerSettingsBreadcrumb from '@/components/ManagerSettingsBreadcrumb.vue';

@Component ({
    components: {
        HealthCheckApiCheckIconContainer,
        ManagerSettingsBreadcrumb
    }
})
export default class TopBar extends Vue {
    @Prop({type:String, required: false, default:'Client'}) currentRouteName?: string;

    get isHealthCheckRoute (): boolean {
        return this.currentRouteName === 'HealthCheck';
    }

    get selectedSchemeCurrency (): string {
        return this.$store.state.selectedScheme.defaultCurrency;
    }

}
</script>

<style lang="scss" scoped>
.top-bar {
	display: grid;
	grid-template-columns: repeat(2, auto);
	justify-content: space-between;
	width: 100%;
	height: 6rem;
	border-bottom: 1px solid $SECONDARY;
	line-height: 6rem;
	padding: 0 2rem;
	border-radius: 5px 5px 0 0;
	background-color: $bg-white;
}
</style>
