<template>
    <nav
        v-tooltip="breadcrumb.length > 0 ? breadcrumb.at(-1).name : ''"
        aria-label="Breadcrumb"
        class="breadcrumb"
    >
        <ol>
            <li
                v-for="(item, index) in breadcrumb"
                :key="item.id"
                :aria-current="index === breadcrumb.length - 1 ? 'page' : null"
            >
                {{ item.name }}
            </li>
        </ol>
    </nav>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';
import { IManagerSettingsBreadcrumb } from '@/interfaces/IManagerSettingsBreadcrumb';

@Component({
    components: {},
})
export default class ManagerSettingsBreadcrumb extends Vue {
  @Prop({type: Array, required: true}) breadcrumb: IManagerSettingsBreadcrumb[];
}
</script>

<style lang="scss" scoped>
nav {
	overflow: hidden;
	white-space: nowrap;
}

nav.breadcrumb ol {
	margin: 0;
	padding-left: 0;
	list-style: none;
	overflow: hidden;
	text-overflow: ellipsis;
}

nav.breadcrumb li {
	display: inline;
}

nav.breadcrumb li + li::before {
	display: inline-block;
	margin: 0 0.25rem;
	content: "/";
}

nav.breadcrumb [aria-current="page"] {
	font-weight: bold;
	color: #000;
	text-decoration: none;
	pointer-events: none;
	&::before {
		font-weight: normal;
	}
}
</style>
