import { render, staticRenderFns } from "./ManagerSettingsBreadcrumb.vue?vue&type=template&id=1665b993&scoped=true"
import script from "./ManagerSettingsBreadcrumb.vue?vue&type=script&lang=ts"
export * from "./ManagerSettingsBreadcrumb.vue?vue&type=script&lang=ts"
import style0 from "./ManagerSettingsBreadcrumb.vue?vue&type=style&index=0&id=1665b993&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1665b993",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\a\\1\\s\\monitor-pro\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('1665b993')) {
      api.createRecord('1665b993', component.options)
    } else {
      api.reload('1665b993', component.options)
    }
    module.hot.accept("./ManagerSettingsBreadcrumb.vue?vue&type=template&id=1665b993&scoped=true", function () {
      api.rerender('1665b993', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/ManagerSettingsBreadcrumb.vue"
export default component.exports