<template>
    <div class="client-login-container">
        <div
            class="wrapper wrapper--header"
            :class="{ 'two-column': setupStatusOpen }"
        >
            <div class="column" />
            <div
                v-if="areArraysIndexedEqual"
                class="column management"
            >
                <div
                    v-show="setupStatusOpen"
                    class="grid"
                >
                    <div class="cell">
                        Last pending update&nbsp;
                        <font-awesome-icon
                            v-tooltip="'Clients with a calibration point added after the last live update will be highlighted.'"
                            :icon="['fad', 'info-circle']"
                        />
                    </div>
                    <div class="cell">
                        Last live update&nbsp;
                        <font-awesome-icon
                            v-tooltip="'Live updates older than 3 months will be shown in red'"
                            :icon="['fad', 'info-circle']"
                        />
                    </div>
                    <div class="cell">
                        Days since live update
                    </div>
                </div>
            </div>
        </div>
        <div
            v-for="(client, index) in filteredClients"
            :key="client.clientEntityID"
            class="wrapper"
            :class="{ 'two-column': setupStatusOpen }"
        >
            <button
                type="button"
                class="btn column"
                @click="handleClick(client.clientEntityID)"
            >
                {{ client.clientEntityNameDisplayName }}
                <font-awesome-icon
                    :icon="['fad', 'user']"
                    class="icon"
                />
            </button>
            <div
                v-if="areArraysIndexedEqual"
                class="column management"
            >
                <div
                    v-show="setupStatusOpen"
                    class="grid"
                >
                    <div
                        class="cell"
                        :class="{
                            highlighted: isPushedToPendingAfterLive(setupStatus[index].lastCalibrationPointAdded, setupStatus[index].lastPushToLive),
                        }"
                    >
                        {{ getFormattedDate(setupStatus[index].lastCalibrationPointAdded) }}
                    </div>
                    <div
                        class="cell"
                        :class="getDateSuccessClass(setupStatus[index].lastPushToLive)"
                    >
                        {{ getFormattedDate(setupStatus[index].lastPushToLive) }}
                    </div>
                    <div class="cell">
                        {{ getDaysSinceLastLivePush(setupStatus[index].lastPushToLive) }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { IUserManagementPageData } from '@/interfaces/user/IUserManagementPageData';
import dayjs from 'dayjs';
import {FORMAT_DATE_UI} from '@/constants';
import Client from '@/models/Client';
import { Component, Vue, Prop } from 'vue-property-decorator';
import VTooltip from 'v-tooltip';

Vue.use(VTooltip);

const HISTORIC_OFFSET_IN_MONTHS = 3;

@Component({ inheritAttrs: false })
export default class ClientLoginContainer extends Vue {
    @Prop({ required: true, type: Array }) setupStatus!: Array<IUserManagementPageData>;

    @Prop({ required: true, type: Array }) filteredClients!:Array<Client>;

    @Prop({ required: true, type: Boolean }) setupStatusOpen!:boolean;

    public get areArraysIndexedEqual (): boolean {
        if (this.setupStatus.length !== this.filteredClients.length) return false;

        for (let i = 0; i < this.setupStatus.length; i++) {
            if (this.setupStatus[i].clientId !== this.filteredClients[i].clientEntityID) return false;
        }

        return true;
    }

    handleClick (clientId: string) :void{
        this.$emit('select-client-by-id', clientId);
    }

    getFormattedDate (dateString: string): string {
        if (!dateString) return '-';

        return dayjs(dateString).format(FORMAT_DATE_UI);
    }

    getDateSuccessClass (dateString: string): string {
        if (!dateString) return '';

        return dayjs(dateString).isBefore(dayjs().subtract(HISTORIC_OFFSET_IN_MONTHS, 'months')) ? 'warn' : 'success';
    }

    isPushedToPendingAfterLive (lastCalibrationPointAdded:string, lastPushToLive: string): boolean {
        if (!lastCalibrationPointAdded) return false;

        return !lastPushToLive || dayjs(lastPushToLive).isBefore(dayjs(lastCalibrationPointAdded));
    }

    getDaysSinceLastLivePush (lastPushToLive: string): number | '-' {
        if (!lastPushToLive) return '-';

        return dayjs().diff(dayjs(lastPushToLive), 'days');
    }
}
</script>

<style lang="scss" scoped>
@use "@/assets/scss/abstracts/_variables" as var;
.client-login-container {
	display: grid;
	row-gap: 1rem;
	max-height: 65vh;
	overflow-y: scroll;
}
.grid {
	height: 100%;
	display: grid;
	justify-content: center;
	align-items: center;
	grid-template-columns: repeat(3, 1fr);
	color: white;
	border-radius: 0.6rem;
	gap: 0.25rem;
	.cell {
		background-color: var.$lcpDarkBlue;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 0.25rem 0.5rem;

		&.highlighted {
			background-color: $lcpBlue;
		}

		&.warn {
			background-color: $ragRed;
		}

		&.success {
			background-color: $ragGreen;
		}
	}
}

.btn {
	text-align: left;
	background: var.$lcpDarkBlue;
	color: white;
	padding-top: 2rem;
	padding-right: 6rem;
	padding-bottom: 2rem;
	padding-left: 3rem;
	border-radius: 0.6rem;
	font-size: 2rem;
	border: 0.2rem solid var.$lcpDarkBlueShadow;
	border-top-width: 0.0625rem;
	border-bottom-width: calc(0.4rem + 0.0625rem);
	position: relative;
	background-size: 300% 100%;
	cursor: pointer;
	user-select: none;
	transition: all 0.4s ease-in-out;
	background-image: linear-gradient(to right, var.$lcpDarkBlue, var.$lcpMidBlue, var.$lcpDarkBlue, var.$lcpMidBlue);

	&:hover,
	&:focus {
		background-position: 100% 0;
		transition: all 0.4s ease-in-out;
	}

	&:active {
		transform: translateY(0.3rem);
		border-bottom-width: calc(0.1rem + 0.0625rem);
		transition: all 0.1s ease-in-out;
	}

	.icon {
		position: absolute;
		right: 2rem;
		top: 50%;
		transform: translateY(-50%);
		color: white;
		opacity: 0.5;
	}
}
.wrapper {
	display: flex;
	gap: 1rem;

	.column {
		transition: flex 300ms ease-in-out, opacity 300ms ease-in-out;
		flex: 0 0 100%;
	}
	.management {
		opacity: 0;
		flex: 0;
	}
	&.two-column {
		.column {
			flex: 1 1 50%;
		}
		.management {
			opacity: 1;
		}
	}
	&--header {
		position: sticky;
		top: 0;
		height: 0;
		overflow: hidden;
		font-size: 1.5rem;
		border-bottom: 1px solid white;
		&.two-column {
			height: 6rem;
		}
	}
}
</style>
