<template>
    <transition
        name="slide"
        appear
    >
        <div
            v-if="isVisible"
            class="slide-in-component"
        >
            <button
                type="button"
                class="btn-close"
                @click="closeModal"
            >
                &times;
            </button>
            <!-- Your component content -->
            <slot />
        </div>
    </transition>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';
@Component

export default class SlideInModal extends Vue {
    @Prop({type: Boolean, required: true}) isVisible!: boolean;

    closeModal ():void {
        this.$emit('close');
    }
}
</script>

<style lang="scss" scoped>
.slide-in-component {
	transition: all 0.5s ease;
	position: fixed;
	top: 0;
	right: 0;
	width: auto;
	height: calc(100% - 10px);
	background-color: #fff;
	z-index: 100;
    margin-top: 5px;
    margin-bottom: 5px;
    border-left: grey 1px solid;
    padding: 2rem 1rem;
}
.btn-close {
    position: absolute;
    top: 5px;
    right: 5px;
    font-size: 2rem;
}

.slide-enter-active {
	transform: translateX(200%);
}

.slide-leave-active {
	transform: translateX(100%);
}
</style>
