<template>
    <div>
        <button
            v-tooltip="tooltip"
            type="button"
            class="api-btn"
            @click="isVisible = true"
        >
            Monitor Pending &amp; Responsible Investment APIs status
            <font-awesome-icon
                class="fa-fw"
                :icon="['fal', icon]"
                :spin="isPending"
                :class="classObject"
            />
        </button>

        <SlideInModal
            :is-visible="isVisible"
            @close="isVisible = false"
        >
            <div class="apis">
                <HealthCheckApiCheckIcon
                    :loading-status="loadingStatusDataStreams"
                    :is-successful="isSuccessfulDataStreams"
                    name="GetDataStreams"
                    description="This API is used to load the selected scheme."
                />
                <HealthCheckApiCheckIcon
                    :loading-status="loadingStatusSnapshotTable"
                    :is-successful="isSuccessfulSnapshotTable"
                    name="GetSnapshotTable"
                    description="This API is used to load the snapshot table for the selected scheme."
                />
                <HealthCheckApiCheckIcon
                    :loading-status="loadingStatusRiAnalytics"
                    :is-successful="isSuccessfulRiAnalytics"
                    name="RiAnalytics"
                    description="This API is used to load the Responsible Investment analytics for the selected scheme."
                />
                <HealthCheckApiCheckIcon
                    :loading-status="loadingStatusRiAnalyticsLcpAlternatives"
                    :is-successful="isSuccessfulRiAnalyticsLcpAlternatives"
                    name="RiAnalyticsLcpAlternatives"
                    description="This API is used to load the Responsible Investment LCP Alternatives for the selected scheme."
                />
            </div>
        </SlideInModal>
    </div>
</template>

<script lang="ts">

import APIChecker from '@/api/apiChecker';
import { Component, Vue, Prop } from 'vue-property-decorator';
import HealthCheckApiCheckIcon from '@/components/MonitorManager/HealthCheckApiCheckIcon.vue';
import SlideInModal from '@/components/SlideInModal.vue';
import {TApiLoadingStatus} from '@/models/TApiLoadingStatus';
import { IGetSnapshotTableRequest } from '@/interfaces/dto/IGetSnapshotTableRequest';
import { IGetRiAnalyticsRequest } from '@/interfaces/dto/IGetRiAnalyticsRequest';
import { IGetRiAnalyticsLcpAlternativesRequest } from '@/interfaces/dto/IGetRiAnalyticsLcpAlternativesRequest';
import { IGetDataStreamsRequestDto } from '@/interfaces/dto/IGetDataStreamsRequestDto';
@Component ({
    components: {
        HealthCheckApiCheckIcon,
        SlideInModal

    }
})
export default class HealthCheckApiCheckIconContainer extends Vue {

    @Prop({type:String, required: true}) selectedSchemeCurrency: string;

    private loadingStatusDataStreams: TApiLoadingStatus = 0;

    private isSuccessfulDataStreams = false;

    private loadingStatusSnapshotTable:TApiLoadingStatus = 0;

    private isSuccessfulSnapshotTable = false;

    private loadingStatusRiAnalytics: TApiLoadingStatus = 0;

    private isSuccessfulRiAnalytics = false;

    private loadingStatusRiAnalyticsLcpAlternatives: TApiLoadingStatus = 0;

    private isSuccessfulRiAnalyticsLcpAlternatives = false;

    private dataStreamsCalculationPeriod: {
        end: string;
        start: string;
    } = {
            end: '',
            start: '',
        };

    private isVisible = false;

    get classObject (): {
        success: boolean;
        error: boolean;
        } {
        return {
            success: !this.isPending && this.isSuccessful,
            error: !this.isPending && !this.isSuccessful
        };
    }

    get icon (): string {
        return this.isPending ? 'spinner'
            : this.isSuccessful ? 'check-circle'
                : 'exclamation-triangle';
    }

    formatDate (date: Date): string {
        return `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getFullYear()}`;
    }

    get startDate (): string {
        const date = new Date(this.dataStreamsCalculationPeriod.start);
        return this.formatDate(date);
    }

    get endDate (): string {
        const date = new Date(this.dataStreamsCalculationPeriod.end);
        return this.formatDate(date);
    }

    get isPending (): boolean {
        return this.loadingStatusDataStreams === 1 || this.loadingStatusSnapshotTable === 1 || this.loadingStatusRiAnalytics === 1 || this.loadingStatusRiAnalyticsLcpAlternatives === 1;
    }

    get isSuccessful (): boolean {
        return this.isSuccessfulDataStreams && this.isSuccessfulSnapshotTable && this.isSuccessfulRiAnalytics && this.isSuccessfulRiAnalyticsLcpAlternatives;
    }

    get tooltip (): string {
        return this.isPending ? 'Waiting for APIs'
            : this.isSuccessful ? 'Monitor Pending &amp; Responsible Investment were run successfully'
                : 'View API status';
    }

    private async checkSnapshotTable (): Promise<void> {
        this.loadingStatusSnapshotTable = 1;

        const payload: IGetSnapshotTableRequest = {
            currency: this.selectedSchemeCurrency,
            endDate: this.endDate,
            startDate: this.startDate,
            livePortfolio: false,
        };
        try {
            const status = await APIChecker.getSnapshotTable(payload);
            if (status === 200) {
                this.isSuccessfulSnapshotTable = true;
            }
        }
        catch(error) {
            this.isSuccessfulSnapshotTable = false;
        } finally {
            this.loadingStatusSnapshotTable = 2;
        }
    }

    private async checkDataStreams (): Promise<void> {
        this.loadingStatusDataStreams = 1;

        const payload:IGetDataStreamsRequestDto = {
            currency: this.selectedSchemeCurrency,
            startDate: null,
            endDate: null,
            livePortfolio: false,
        };

        try {
            const {status, data } = await APIChecker.getDataStreams(payload);

            if (status === 200) {
                this.isSuccessfulDataStreams = true;
                const dateRange = data.performanceCalculationPeriods.find((period: any) => period.key === 'Requested calculation period');
                if (dateRange) {
                    this.dataStreamsCalculationPeriod = dateRange.period;
                    this.checkSnapshotTable();
                    this.checkRiAnalytics();
                }
            }
        }
        catch (error) {
            this.isSuccessfulDataStreams = false;
        } finally {
            this.loadingStatusDataStreams = 2;
        }
    }

    private async checkRiAnalytics (): Promise<void> {
        this.loadingStatusRiAnalytics = 1;

        try {
            const payload: IGetRiAnalyticsRequest = {
                startDate: this.dataStreamsCalculationPeriod.start.split('T')[0],
                endDate: this.dataStreamsCalculationPeriod.end.split('T')[0],
                currency: this.selectedSchemeCurrency,
                livePortfolio: false,
            };
            const {status, data} = await APIChecker.getRiAnalytics(payload);

            if (status === 200) {
                this.isSuccessfulRiAnalytics = true;
                const fundList = data.entityRiResults.filter((result: { isLeaf: boolean;  }) => result.isLeaf);
                const entities = fundList.map((fund: { id: number; latestMarketValue: { date: string; amount: number; }; }) => ({
                    id: fund.id,
                    date: fund.latestMarketValue.date,
                    marketValue: fund.latestMarketValue.amount,
                }));
                this.checkRiAnalyticsLcpAlternatives(entities);

            }
        }
        catch (error) {
            this.isSuccessfulRiAnalytics = false;
        } finally {
            this.loadingStatusRiAnalytics = 2;
        }
    }

    private async checkRiAnalyticsLcpAlternatives (entities:any): Promise<void> {
        this.loadingStatusRiAnalyticsLcpAlternatives = 1;

        try {
            const payload:IGetRiAnalyticsLcpAlternativesRequest = {
                startDate: this.dataStreamsCalculationPeriod.start.split('T')[0],
                endDate: this.dataStreamsCalculationPeriod.end.split('T')[0],
                entities,
                currency: this.selectedSchemeCurrency,
            };
            const status = await APIChecker.getRiAnalyticsLcpAlternatives(payload);

            if (status === 200) {
                this.isSuccessfulRiAnalyticsLcpAlternatives = true;
            }
        }
        catch (error) {
            this.isSuccessfulRiAnalyticsLcpAlternatives = false;
        } finally {
            this.loadingStatusRiAnalyticsLcpAlternatives = 2;
        }

    }

    created (): void {
        this.checkDataStreams();
    }

}

</script>
<style lang="scss" scoped>
.name {
	display: inline-block;
}
.api-btn {
	padding: 1rem;
	background-color: transparent;
	border: none;
	border-radius: 0.5rem;
	font-size: 1.2rem;
	&:hover,
	&:focus,
	&:active {
		background-color: #f8f9fa;
	}
}
.success {
	color: $ragGreen;
}
.error {
	color: $ragRed;
}
.apis {
	display: grid;
	grid-gap: 2rem;
}
</style>