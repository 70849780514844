import {IManagerAssetValuesAndCashflowsDto} from '@/interfaces/entity/IManagerAssetValuesAndCashflowsDto';
import ApiHelper from './apiHelper';
import ManagerItem from '@/models/AssetValueAndCashFlow';

export default class MarketValueAndCashflowApi {
    static async getClientAssetValuesAndCashflows (): Promise<Array<IManagerAssetValuesAndCashflowsDto>> {
        return (await ApiHelper.post('GetClientAssetValuesAndCashflows', {})).data as Array<IManagerAssetValuesAndCashflowsDto>;
    }

    static async updateClientAssetValuesAndCashflows (saveData: ManagerItem[]): Promise<any> {
        try {
            const { data } = await ApiHelper.post('UpdateClientAssetValuesAndCashflows', {data: saveData});
            return data;
        } catch (error) {
            //error
        }
    }

    static async getClientExposureValuesAndFlows (): Promise<Array<IManagerAssetValuesAndCashflowsDto>> {
        return (await ApiHelper.post('GetClientExposureValuesAndFlows', {})).data as IManagerAssetValuesAndCashflowsDto[];
    }

    static async updateClientExposureValuesAndFlows (saveData: ManagerItem[]): Promise<any> {
        return (await ApiHelper.post('UpdateClientExposureValuesAndFlows', {data: saveData})).data as any;
    }

}
