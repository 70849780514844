<template>
    <div class="client">
        <div
            class="left"
            :class="{ expanded: managementTableOpen }"
        >
            <div class="logo">
                <h1>LCP Monitor Pro</h1>
            </div>
            <loading-overlay v-if="isLoading" />
            <div
                v-else
                class="client-holder"
            >
                <div class="client-holder-header">
                    <div class="search-wrapper">
                        <label for="search">Please select a client from the list below:</label>
                        <input
                            id="search"
                            ref="searchInput"
                            v-model="clientSearchText"
                            type="text"
                            placeholder="Search..."
                            @keyup.enter="handleKeyup"
                        >
                    </div>
                    <div
                        v-tooltip="!managementData.length ? 'Loading scheme setup status' : 'Toggle scheme setup status'"
                        class="open-management"
                    >
                        <transition
                            name="fade"
                            mode="out-in"
                        >
                            <font-awesome-icon
                                v-if="!managementData.length"
                                class="loader show fa-fw"
                                :icon="['fal', 'spinner']"
                                spin
                            />
                            <button
                                v-else
                                type="button"
                                @click="toggleManagementTable"
                            >
                                <font-awesome-icon
                                    :icon="['fad', 'info-circle']"
                                    class="icon"
                                />
                            </button>
                        </transition>
                    </div>
                </div>
                <p
                    v-if="clientsLength === 0"
                    class="mt-5"
                >
                    You don't have any client access set up
                </p>
                <p
                    v-else-if="filteredClientsLength === 0"
                    class="mt-5"
                >
                    No Clients match the search
                </p>
                <ClientLoginContainer
                    v-else
                    :setup-status="filteredManagementData"
                    :filtered-clients="filteredClients"
                    :setup-status-open="managementTableOpen"
                    @select-client-by-id="selectClientById"
                />
            </div>
            <div>
                If your client isn't in the list go to
                <a
                    class="link-item"
                    href="https://authorise.lcp.uk.com/"
                    target="_blank"
                >LCP Authorise</a>
                to set them up.
            </div>
        </div>
        <div class="right">
            <parallelograms />
            <div class="inner-text">
                <div class="heading">
                    One tool,
                </div>
                <div class="heading">
                    All of your investment monitoring needs
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import Parallelograms from '@/components/Parallelograms.vue';
import LoadingOverlay from '@/components/LoadingOverlay.vue';
import ClientLoginContainer from '@/components/ClientLoginContainer.vue';
import Client from '@/models/Client';
import { IUserManagementPageData } from '@/interfaces/user/IUserManagementPageData';
import UserApi from '@/api/userApi';
import VTooltip from 'v-tooltip';

Vue.use(VTooltip);
@Component({
    components: {
        Parallelograms,
        LoadingOverlay,
        ClientLoginContainer
    },
})
export default class ClientView extends Vue {
    public isLoading = true;

    public managementTableOpen = false;

    public managementData: Array<IUserManagementPageData> = [];

    public clientSearchText = '';

    public updateSelectedScheme (client: Client): void {
        this.$store.dispatch('updateSelectedScheme', client);
        this.$store.dispatch('updateClientDisplayName', client.clientEntityNameDisplayName);
        this.$store.dispatch('updateBreadcrumb', [{id: -1, name:client.clientEntityNameDisplayName}]);

        this.$router.push('/manager-settings');
    }

    public get clients (): Array<Client> {
        return this.$store.state.clients;
    }

    public get clientsLength (): number {
        return this.clients.length;
    }

    public get filteredClients (): Array<Client> {
        return this.clients.filter((client) => client.clientEntityNameDisplayName.toLowerCase().includes(this.clientSearchText.toLowerCase()));
    }

    public get filteredClientsLength (): number {
        return this.filteredClients.length;
    }

    public get filteredManagementData (): Array<IUserManagementPageData> {
        return this.managementData.filter((client) => client.name.toLowerCase().includes(this.clientSearchText.toLowerCase()));
    }

    handleKeyup ():void {
        if (this.filteredClients.length === 1) {
            this.selectClientById(this.filteredClients[0].clientEntityID);
        }
    }

    selectClientById (clientId: string):void {
        const result = this.clients.find((client) => client.clientEntityID === clientId);
        if (!result) return;
        this.updateSelectedScheme(result);
    }

    focusSearch (): void {
        if (!this.$refs.searchInput) return;
        (this.$refs.searchInput as HTMLInputElement).focus();
    }

    async fetchManagementData (): Promise<void> {
        try {
            const managementData = await UserApi.getUserManagementPageData();
            this.managementData = managementData;
        } catch (error) {
            this.$store.dispatch('pushNetworkErrorMessage', 'Error fetching user management data');
        }
    }

    async fetchUserSchemes (): Promise<void> {
        try {
            const userSchemes = await UserApi.getUserSchemes();
            this.$store.commit('updateClients', userSchemes);
        } catch (error) {
            this.$store.dispatch('pushNetworkErrorMessage', 'Error fetching user schemes');
        } finally {
            this.isLoading = false;
            this.$nextTick(() => {
                this.focusSearch();
            });
        }
    }

    created (): void {
        this.fetchManagementData();
        this.fetchUserSchemes();
    }

    public toggleManagementTable (): void {
        this.managementTableOpen = !this.managementTableOpen;
    }
}

</script>

<style lang="scss" scoped>
@import "@/assets/scss/abstracts/_mixin.scss";
.mt-5 {
	margin-top: 5rem;
}
.client {
	display: flex;
	overflow: hidden;

	.left,
	.right {
		flex: 1;
		height: 100svh;
		transition: flex-basis 1s;
	}

	.left {
		background-color: $bg-white;
		position: relative;

		&.expanded {
			flex-basis: 80%;

			&+.right {
                 flex-basis: auto;
                 .inner-text {
                     opacity: 0;
                 }
			}
		}

		.client-holder {
			margin-bottom: 6rem;

			.client-name {
				display: flex;
				margin-top: 4rem;
				max-height: 65vh;
				overflow-y: scroll;
				position: relative;

				.client-list {
					margin-top: 0;
					max-width: 800px;
					transition: margin-top 1s;
					width: calc(50vw - 100px);

					.btn-content {
						height: calc(85px - 1rem);
					}

					&.margin {
						margin-top: 5rem;
					}

					.small {
						font-size: 16px;
					}
				}
			}

			.client-holder-header {
				align-items: center;
				display: flex;
				justify-content: space-between;
                margin-bottom: 1rem;
			}

			.search-wrapper {
				display: flex;
				align-items: center;
				column-gap: 1rem;
				flex: 1;
			}

			input {
				flex: 0 0 300px;
				border: 1px solid $lcpDarkBlue;
				border-radius: 4px;
				padding: 1rem;
			}
		}

		.open-management {
			align-items: center;
			display: flex;
			flex-direction: column;
			gap: 1rem;
			width: fit-content;
			position: relative;

			button {
				color: $lcpDarkBlue;
				font-size: 3rem;

				&:disabled {
					cursor: not-allowed;
				}
			}

			.loader {
				opacity: 0;
				transition: opacity 0.3s;
				font-size: 3rem;

				&.show {
					opacity: 1;
				}
			}
		}
	}

	.right {
		background: $lcpDarkBlue;
		color: $text-white;
		background-repeat: no-repeat;
		background-position: right bottom;
		position: relative;

		@include responsive("md") {
			display: none;
		}

		.inner-text {
            transition: opacity 500ms;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			max-width: 350px;

			.heading {
				font-size: 3rem;
				font-weight: 300;
			}
		}
	}

	.left {
        padding: 5rem;
		overflow-y: auto;
		position: relative;
		@include responsive("md") {
			width: 100% !important;
			height: 100svh;
			padding: 2rem !important;
		}

		.absolute {
			position: absolute;
		}

		.logo {
            margin-bottom: 1rem;
			img {
				height: 5rem;
			}
		}

		.register {
			margin: 1rem;
			text-decoration: underline;
			cursor: pointer;
		}

		.footer {
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			padding: 1rem 7rem;
			display: flex;
			justify-content: space-between;
			font-size: 1.4rem;

			a {
				text-decoration: underline;
			}
		}

		.management-table-wrapper {
			color: #eee;
			left: 45vw;
			position: absolute;
			transition: opacity 0.3s;
			display: none;

			&.show {
				display: block;
				opacity: 1;
			}
		}
	}
}
</style>
