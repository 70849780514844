<template>
    <nav class="navbar">
        <div class="logo">
            <router-link to="/">
                LCP Monitor Pro
            </router-link>
        </div>
        <div
            class="nav-items-wrapper"
            :class="{ disabled: isDisabled }"
        >
            <div>
                <div class="nav-item cursor-default">
                    <span class="nav-icon"><font-awesome-icon :icon="['fal', 'cogs']" /></span>
                    <span>Manager setup</span>
                </div>
                <div class="sub-nav">
                    <router-link
                        v-for="(managerSettingsLink, index) in managerSettingsLinks"
                        :key="index"
                        :to="managerSettingsLink.routeTo"
                    >
                        <div
                            class="nav-item"
                            :class="{ 'active-item': $route.name === managerSettingsLink.routeName }"
                        >
                            {{ managerSettingsLink.name }}
                        </div>
                    </router-link>
                </div>
            </div>
            <div>
                <div class="nav-item cursor-default">
                    <span class="nav-icon"><font-awesome-icon :icon="['fal', 'analytics']" /></span>
                    <span>Manager data</span>
                </div>
                <div class="sub-nav">
                    <transition-group
                        name="fade"
                        tag="div"
                    >
                        <router-link
                            v-for="managerDataLink in managerDataLinks"
                            :key="managerDataLink.name"
                            :to="managerDataLink.routeTo"
                        >
                            <div
                                class="nav-item"
                                :class="{ 'active-item': $route.name === managerDataLink.routeName }"
                            >
                                {{ managerDataLink.name }}
                            </div>
                        </router-link>
                    </transition-group>
                </div>
            </div>
            <router-link to="/health-check">
                <div
                    class="nav-item"
                    :class="{ 'active-item': $route.name === 'HealthCheck' }"
                >
                    <span class="nav-icon"><font-awesome-icon :icon="['far', 'heartbeat']" /></span>
                    <span>Health Check</span>
                </div>
            </router-link>
            <div>
                <div class="nav-item cursor-default">
                    <span class="nav-icon"><font-awesome-icon :icon="['far', 'wrench']" /></span>
                    <span>General settings</span>
                </div>
                <div class="sub-nav">
                    <router-link
                        v-for="(generalSettingsLink, index) in generalSettingsLinks"
                        :key="index"
                        :to="generalSettingsLink.routeTo"
                    >
                        <div
                            class="nav-item"
                            :class="{ 'active-item': $route.name === generalSettingsLink.routeName }"
                        >
                            {{ generalSettingsLink.name }}
                        </div>
                    </router-link>
                </div>
            </div>
            <router-link to="/release-audit">
                <div
                    class="nav-item"
                    :class="{ 'active-item': $route.name === 'ReleaseAudit' }"
                >
                    <span class="nav-icon"><font-awesome-icon :icon="['fal', 'history']" /></span>
                    <span>Release audit</span>
                </div>
            </router-link>
            <router-link to="/fund-recommendation-report">
                <div
                    class="nav-item"
                    :class="{ 'active-item': $route.name === 'FundRecommendationReport' }"
                >
                    <span class="nav-icon"><font-awesome-icon :icon="['fal', 'badge-check']" /></span>
                    <span>Fund recommendation form</span>
                </div>
            </router-link>
            <div class="nav-item cursor-default">
                <div class="nav-divider" />
            </div>
            <div>
                <div
                    class="nav-item"
                    @click="toggleExternalSiteLinks()"
                >
                    <span class="nav-icon">
                        <font-awesome-icon :icon="['fal', 'link']" />
                    </span>
                    <span>External links</span>
                    <font-awesome-icon
                        :icon="['far', 'chevron-down']"
                        class="dropdown-icon"
                        :class="{ flipped: showExternalSiteLinks }"
                    />
                </div>
                <transition-group
                    name="fade"
                    mode="out-in"
                >
                    <div
                        v-if="showExternalSiteLinks"
                        key="sub-nav"
                        class="sub-nav"
                    >
                        <a
                            v-for="link in externalSiteLinks"
                            :key="link.routeName"
                            target="_blank"
                            :href="link.routeTo"
                        >
                            <div class="sub-nav-item">
                                <span>{{ link.name }}</span>
                            </div>
                        </a>
                    </div>
                </transition-group>
            </div>
            <div
                class="nav-item"
                @click="logout"
            >
                <span class="nav-icon"><font-awesome-icon :icon="['fal', 'sign-out']" /></span>
                <span>Log out</span>
            </div>
            <div class="nav-item cursor-default">
                <span>Site version: {{ version }}</span>
            </div>
        </div>
    </nav>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import MarketValueAndCashflowApi from '@/api/marketValueAndCashflowApi';
import { IManagerAssetValuesAndCashflowsDto } from '@/interfaces/entity/IManagerAssetValuesAndCashflowsDto';

interface IRouteLink {
    name: string;
    routeName: string;
    routeTo: string;
}

const managerDataLinks: Array<IRouteLink> = [
    {
        name: 'Market values',
        routeName: 'MarketValueCashflow',
        routeTo: '/market-value-cashflow',
    },
    {
        name: 'Performance',
        routeName: 'Performance',
        routeTo: '/performance',
    },
    {
        name: 'Benchmark performance',
        routeName: 'BenchmarkPerformance',
        routeTo: '/benchmark-performance',
    },
    {
        name: 'Bulk operations',
        routeName: 'BulkOperations',
        routeTo: '/bulk-operations',
    },
];

const managerSettingsLinks = [
    {
        name: 'Manager settings',
        routeName: 'ManagerSettings',
        routeTo: '/manager-settings',
    },
    {
        name: 'Strategic allocations',
        routeName: 'StrategicAllocations',
        routeTo: '/strategic-allocations',
    }
];

const generalSettingsLinks : Array<IRouteLink> = [
    {
        name: 'Alternative performance settings',
        routeName: 'AlternativeCalculationsSettings',
        routeTo: '/alternative-calculations-settings',
    },
    {
        name: 'Client setup',
        routeName: 'ClientSetup',
        routeTo: '/client-setup',
    }
];

Object.freeze(managerDataLinks);
Object.freeze(managerSettingsLinks);
Object.freeze(generalSettingsLinks);

@Component
export default class NavBar extends Vue {
    managerDataLinks = [...managerDataLinks];

    managerSettingsLinks = managerSettingsLinks;

    generalSettingsLinks = generalSettingsLinks;

    showExternalSiteLinks = false;

    isDisabled = true;

    async checkIfExposureValuesPresent (): Promise<void> {
        this.isDisabled = true;
        let response: IManagerAssetValuesAndCashflowsDto[] = [];

        const exposureValuesLink: IRouteLink = {
            name: 'Exposure values',
            routeName: 'ExposureValues',
            routeTo: '/exposure-values',
        };
        try {
            response = await MarketValueAndCashflowApi.getClientExposureValuesAndFlows();
        } catch (error) {
            this.$store.dispatch('pushNetworkErrorMessage', 'Error fetching data');
        } finally {
            if (response && response.length !== 0) {
                this.managerDataLinks = [
                    this.managerDataLinks[0],
                    exposureValuesLink,
                    ...this.managerDataLinks.slice(1),
                ];
            }
            this.isDisabled = false;
        }
    }

    mounted () {
        this.checkIfExposureValuesPresent();
    }

    toggleExternalSiteLinks (): void {
        this.showExternalSiteLinks = !this.showExternalSiteLinks;
    }

    get showGeneralSettings () : boolean {
        return generalSettingsLinks.length > 0;
    }

    public logout (): void {
        this.$store.dispatch('auth/logout').then(() => {
            this.$store.dispatch('auth/getToken');
        });
    }

    public version = process.env.VUE_APP_VERSION_NUMBER ?? -1;

    public isAltCalcsFeatureLive: boolean = process.env.VUE_APP_FEATURE_ALTERNATIVE_CALCS === 'true';

    get externalSiteLinks (): Array<IRouteLink> {
        return [
            {
                name: 'Guidance and materials',
                routeName: 'GuidanceAndMaterials',
                routeTo: 'https://lcponline.sharepoint.com/teams/DBPensionSchemes/SitePages/LCP-Monitor---guidance-and-materials-hub.aspx',
            },
            {
                name: 'Authorise',
                routeName: 'Authorise',
                routeTo: process.env.VUE_APP_AUTHORISE_URL,
            },
            {
                name: 'Monitor Pending',
                routeName: 'MonitorPending',
                routeTo: process.env.VUE_APP_PENDING_URL,
            },
            {
                name: 'Monitor Live',
                routeName: 'MonitorLive',
                routeTo: process.env.VUE_APP_MONITOR_URL,
            },
            {
                name: 'Investment Research',
                routeName: 'investmentResearch',
                routeTo: process.env.VUE_APP_INVESTMENT_RESEARCH_URL,
            },
        ];
    }
}

</script>

<style lang="scss" scoped>
@use "@/assets/scss/abstracts/_breakpoints.scss";

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
  transform: translateY(10px);
}

.fade-move {
  transition: transform 0.5s ease;
}

.navbar {
    height: 100%;
    color: $text-white;

.disabled {
    cursor: not-allowed;
    opacity: 0.4;
    position: relative;
    overflow: hidden;
    pointer-events: none;
}

.disabled::before {
    content: '';
    position: absolute;
    top: 0;
    left: -150%;
    width: 150%;
    height: 100%;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.2) 50%, rgba(255, 255, 255, 0) 100%);
    animation: shimmer 1.5s infinite;
}

@keyframes shimmer {
    0% {
        left: -150%;
    }
    50% {
        left: 100%;
    }
    100% {
        left: 100%;
    }
}

    .logo {
        position: relative;
        padding: 0 2rem;
        height: 6rem;
        line-height: 6rem;
        font-size: 2rem;
        margin-bottom: 2rem;

        &::after {
            bottom: -1rem;
            content: "";
            display: block;
            width: 100%;
            height: 1px;
            background-color: $bg-white;
        }
    }

    .nav-items-wrapper {
        margin-bottom: 4rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: calc(100% - 8rem);
        overflow-y: auto;

        &::-webkit-scrollbar-track {
            background-color: transparent;
        }
    }

    .nav-item {
        cursor: pointer;
        padding: 1rem 2rem;
        border-left: $lcpDarkBlue 3px solid;

        &.active-item {
            background-color: rgba(145, 193, 242, 0.254);
            border-left: $lcpWatermelon 3px solid;
        }
    }

    .sub-nav {
        position: relative;
        padding-left: 3rem;
        font-size: 1.3rem;

        &::before {
            position: absolute;
            top: 0;

            content: "";
            width: 0.2rem;
            background-color: rgba(255, 255, 255, 0.3);
            height: 100%;
        }
    }

    .sub-nav-item {
        padding: 1rem 0 1rem 2rem;

        &.active-item {
            background-color: rgba(145, 193, 242, 0.254);
            border-left: $lcpWatermelon 3px solid;
        }
    }

    .nav-divider {
        height: 1px;
        background: $bg-white;
        margin-bottom: 1rem;
        margin-top: 2rem;
    }

    .nav-icon {
        display: inline-block;
        margin-right: 1rem;
    }

    .dropdown-icon {
            transition: transform 0.3s;
            margin-left: 0.5rem;

            &.flipped {
                transform: rotate(180deg);
            }
        }

    .cursor-default {
        cursor: default;
    }

    @include breakpoints.responsive("xl") {
        .nav-item {
            padding-left: 1rem;
            padding-right: 1rem;
        }

        .logo {
            font-size: 1.5rem;
            padding-left: 1rem;
            padding-right: 1rem;
        }

        .sub-nav {
            font-size: 1.1rem;
            padding-left: 1rem;
        }

        .sub-nav-item {
            padding-left: 1rem;
            height: auto;
        }
    }

    @include breakpoints.responsive("lg") {
        .logo {
            font-size: 1.3rem;
        }

        .sub-nav {
            font-size: 1rem;
        }
    }
}
</style>
